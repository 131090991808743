<template>
  <f7-page name="Campus-Academy" no-toolbar>
    <f7-navbar :title="render.academy" back-link="Back">
      <f7-nav-right>
        <f7-link
          :href="website"
          icon-ios="f7:world"
          icon-aurora="f7:world"
          icon-md="material:explorer"
          external
        ></f7-link>
        <!-- <f7-link icon-ios="f7:phone" icon-aurora="f7:phone" icon-md="material:phone" @click="phone"></f7-link> -->
      </f7-nav-right>
    </f7-navbar>

    <f7-swiper pagination :params="slidersOptions" class="sliders academy-sliders">
      <f7-swiper-slide v-for="(img, index) in sliders" :key="'slider-'+index">
        <a :href="img.jump_url" class="external" target="_blank">
          <img :alt="img.title" :src="img.picture_url">
        </a>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-block class="grid-apps">
      <f7-row no-gap style="margin-top: 8px;">
        <f7-col width="25">
          <a :href="`/Academy/${render.academy}/Introduction`">
            <f7-col class="col-zsdt col-bg">学院概况</f7-col>
          </a>

          <a :href="`/Academy/${render.academy}/Majors`">
            <f7-col class="col-zszc col-bg">专业介绍</f7-col>
          </a>
        </f7-col>
        <f7-col width="75" class="col-zszn col-bg">
          <a :href="`/Academy/${render.academy}/SocialImpact`">
            <f7-col style="width:100%; height:100%">
              <div class="bkzn-title">
                <div>社会影响</div>
              </div>
            </f7-col>
          </a>
        </f7-col>
      </f7-row>

      <f7-row style="margin-top: 8px;" no-gap>
        <f7-col width="25">
          <a :href="`/Academy/${render.academy}/Alumni`">
            <f7-col class="col-zsjh col-bg">知名校友</f7-col>
          </a>
        </f7-col>
        <f7-col width="75">
          <a :href="`/Academy/${render.academy}/MainJobDirection`">
            <f7-col class="col-xxgk col-bg">就业主要方向及知名就业单位</f7-col>
          </a>
        </f7-col>
      </f7-row>
      <f7-row no-gap>
        <f7-col width="50">
          <a :href="`/Academy/${render.academy}/Graduates`">
            <f7-col class="col-zsdt col-bg">历年毕业生去向</f7-col>
          </a>
        </f7-col>
        <f7-col width="50">
          <a :href="`/Academy/${render.academy}/Scholarship`">
            <f7-col class="col-zszc col-bg">专项奖学金</f7-col>
          </a>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { getAcademy } from "@/api/academy";
export default {
  data() {
    return {
      init: true,
      sliders: [],
      slidersOptions: {
        speed: 500,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
          delay: 3500
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets"
        }
      },
      org_data: {},
      render: {
        academy: "",
        lists: []
      }
    };
  },
  mounted() {
    const self = this;

    this.params = this.$f7route.params;

    setTimeout(() => {
      getAcademy(this.params.academy).then(response => {
        let data = response.data.data;
        self.org_data = data;
        self.render.academy = data.name;
        self.render.info = data;
        self.sliders = data.slides;

        // reload swiper
        self.$f7.swiper.destroy(".academy-sliders");
        setTimeout(() => {
          self.$f7.swiper.create(".academy-sliders", self.slidersOptions);
        }, 50);
      });
    }, 500);
  },
  computed: {
    website() {
      return this.org_data.website;
    },
    hasData() {
      return !!this.render.lists.length;
    }
  },
  methods: {
    phone() {
      console.log(this.org_data);

      location.href = "tel:+" + this.org_data.telephone;
    }
  }
};
</script>

<style lang="sass" scoped>
.sliders {
  height: 250px;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
