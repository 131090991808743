<template>
  <f7-page name="MediumsVideo" hide-toolbar-on-scroll>
    <f7-navbar no-shadow :title="render.article_name" back-link="Back">
      <f7-nav-right>
        <f7-link
          @click="showDetail"
          icon-ios="f7:info"
          icon-aurora="f7:info"
          icon-md="material:info"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block class="content-area" v-if="show" v-html="content">
      <p>content</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { getArticle } from "@/api/common";
export default {
  data() {
    return {
      params: {},
      render: {},
      show: false
    };
  },
  computed: {
    content() {
      console.log(this.render.content_source_url);
      if (!this.render.content_source_url) {
        return "";
      }

      let style = 'style="width:100%; height:100%"';
      let str = this.render.content_source_url.replace(
        /height=.*src/is,
        `${style} src`
      );

      return str;
    }
  },
  mounted() {
    const self = this;
    this.params = this.$f7route.params;

    setTimeout(() => {
      getArticle({
        article_id: self.params.article_id
      }).then(response => {
        console.log(response);
        self.render = response.data.data;
        self.show = true;
      });
    }, 500);
  },
  methods: {
    showDetail() {
      let data = this.render;

      app.dialog
        .create({
          title: "更多信息",
          text: `<p style="text-align:left;">标题：${
            data.article_name
          }<br>作者：${data.author}<br>时间：${data.create_time}</p>`,
          buttons: [
            {
              text: "关闭"
            }
          ]
        })
        .open();
    }
  }
};
</script>

<style lang="sass" scoped>
.title {
    font-size: 30px;
    color: #000;
    font-weight: 600;
    text-align: center;
}
.content-area{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
</style>
