<template>
  <f7-page
    :infinite="loadOptions.allowInfinite"
    :infinite-distance="50"
    :infinite-preloader="loadOptions.showPreloader"
    @infinite="loadMore"
    name="Majors"
  >
    <f7-navbar no-shadow title="招生专业" back-link="Back"></f7-navbar>
    <f7-block v-if="!hasData" strong>这里没有找到相关数据</f7-block>
    <!-- <f7-block-title v-if="hasData" large>招生专业</f7-block-title> -->
    <f7-list v-if="hasData">
      <f7-list-item
        v-for="(item, index) in render"
        :key="index"
        :title="item.article_name"
        :link="`/Article/${item.article_id}`"
      ></f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { getMajors } from "@/api/admission";
import { ListPageDataDeal } from "@/libs/utils";

export default {
  data() {
    return {
      render: [],
      loadOptions: {
        allowInfinite: true,
        showPreloader: true,
        page: 1
      }
    };
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      getMajors({
        page: 1
      }).then(response => {
        ListPageDataDeal(self, response);
      });
    }, 500);
  },
  computed: {
    hasData() {
      return !!this.render.length;
    }
  },

  methods: {
    loadMore() {
      const self = this;

      if (!self.loadOptions.allowInfinite) {
        return;
      }

      getMajors({
        page: ++self.loadOptions.page
      }).then(response => {
        ListPageDataDeal(self, response);
      });
    }
  }
};
</script>

