<template>
  <f7-page name="catalog">
    <f7-navbar no-shadow title="学院目录"></f7-navbar>
    <f7-block v-if="!hasCatalogData" strong>没有获取到学院列表信息</f7-block>
    <f7-list media-list v-if="hasCatalogData">
      <f7-list-item
        v-for="(item,index) in catalogs"
        :key="`unit-item-${index}`"
        :title="item.name"
        :link="`/Campus/Academy/${item.name}`"
        :text="item.description"
      >
        <!-- :subtitle="`电话：${item.telephone}`" -->
        <img slot="media" :src="item.logo_url" width="80" height="80" class="logo">
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
import { getAcademyCatalog } from "@/api/academy";
export default {
  data: function() {
    return {
      catalogs: []
    };
  },
  computed: {
    hasCatalogData() {
      return !!this.catalogs.length;
    }
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      getAcademyCatalog().then(response => {
        console.log(response);
        self.catalogs = response.data.data;
      });
    }, 500);
  }
};
</script>

<style scoped>
.logo {
  border-radius: 50%;
}
</style>
