<template>
  <f7-app :params="f7params">
    <f7-statusbar></f7-statusbar>

    <!-- Right panel with reveal effect-->
    <f7-panel right reveal>
      <f7-view>
        <f7-page>
          <f7-navbar title="菜单"></f7-navbar>
          <f7-list>
            <f7-list-item title="华东交通大学" link="http://www.ecjtu.edu.cn/" external></f7-list-item>
            <f7-list-item title="华东交通大学招生信息网" link="http://zjc.ecjtu.edu.cn/recruit/index" external></f7-list-item>
            <f7-list-item title="华东交通大学就业信息网" link="http://zjc.ecjtu.edu.cn/index" external></f7-list-item>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-panel>

    <f7-views tabs class="safe-areas">
      <f7-toolbar tabbar labels bottom>
        <f7-link
          tab-link="#view-home"
          tab-link-active
          icon-ios="f7:home_fil"
          icon-aurora="f7:home_fil"
          icon-md="material:home"
          text="首页"
        ></f7-link>
        <f7-link
          tab-link="#view-catalog"
          icon-ios="f7:list_fill"
          icon-aurora="f7:list_fill"
          icon-md="material:view_list"
          text="学院概况"
        ></f7-link>
        <f7-link
          tab-link="#view-about"
          icon-ios="f7:chat_bubble"
          icon-aurora="f7:settings_fill"
          icon-md="material:chat_bubble"
          text="联系我们"
        ></f7-link>
      </f7-toolbar>

      <f7-view id="view-home" main tab tab-active url="/" :pushState="true" :reloadCurrent="true"></f7-view>
      <f7-view id="view-catalog" name="catalog" tab url="/AcademyCatalog"></f7-view>
      <f7-view id="view-about" name="about" tab url="/About"></f7-view>
    </f7-views>
  </f7-app>
</template>
<script>
import routes from "../js/routes.js";

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: "华东交通大学招生网", // App name
        theme: "auto", // Automatic theme detection
        // App root data
        data: function() {
          return {};
        },
        view: {
          stackPages: true,
          mdSwipeBack: true,
          restoreScrollTopOnBack: false,
          pushStateRoot: location.pathname
        },
        // 对话框基本设置
        dialog: {
          buttonCancel: "取消",
          buttonOk: "确定",
          title: "系统消息"
        },
        // 通知基本设置
        notification: {
          title: "系统消息",
          titleRightText: "now",
          closeButton: true,
          closeTimeout: 3000
        },
        // App routes
        routes: routes,

        // Register service worker
        serviceWorker: {
          path: "/service-worker.js"
        }
      },

      on: {
        serviceWorkerRegisterSuccess(registration) {
          let update = registration.update();
        }
      }
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.$f7ready(f7 => {
      const app = this.$f7;
      window.app = app;
      // setTimeout(() => {
      //   app.toast
      //     .create({
      //       text: "App处于开发阶段，所有数据均为模拟数据",
      //       position: "center",
      //       closeTimeout: 3500
      //     })
      //     .open();
      // }, 1000);
    });
  }
};
</script>