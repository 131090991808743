<template>
  <f7-page name="Plan">
    <f7-navbar no-shadow title="招生计划" back-link="Back"></f7-navbar>
    <div class="list no-hairlines-md">
      <ul>
        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input
                  type="text"
                  placeholder="点击选择要浏览的省份"
                  readonly="readonly"
                  id="year-city-picker"
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <p class="title" v-if="hasData">{{ search.year }}年 {{ search.city }} 招生计划</p>
    <div v-if="hasData" class="data-table" id="data-table-center">
      <table>
        <thead>
          <tr>
            <th class="label-cell">学院名称</th>
            <th class="numeric-cell">专业名称</th>
            <th class="numeric-cell">录取批次</th>
            <th class="numeric-cell">科类</th>
            <th class="numeric-cell">招生人数</th>
            <th class="numeric-cell">学制(年)</th>
            <th class="numeric-cell">学费标准(元/年)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in render" :key="'tr'+index">
            <td class="label-cell">{{ item.faculty }}</td>
            <td class="numeric-cell">{{ item.major }}</td>
            <td class="numeric-cell">{{ item.batch }}</td>
            <td class="numeric-cell">{{ item.type }}</td>
            <td class="numeric-cell">{{ item.number }}</td>
            <td class="numeric-cell">{{ item.length }}</td>
            <td class="numeric-cell">{{ item.tuition }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </f7-page>
</template>

<script>
import { getPlan, getPlanProvinces } from "@/api/admission";
export default {
  data() {
    return {
      search: {
        year: "",
        city: ""
      },
      pickerData: {
        years: [],
        provinces: []
      },
      render: []
    };
  },
  watch: {
    search: function(newValue, oldValue) {
      if (newValue.year == oldValue.year && newValue.city == oldValue.city) {
        return;
      }

      this.getData();
    }
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      getPlanProvinces()
        .then(response => {
          console.log(response.data);
          self.pickerData = response.data.data;
          self.initPicker();
        })
        .catch(error => {
          console.log(error);
        });
    }, 300);
  },
  computed: {
    hasData() {
      return !!this.render.length;
    }
  },

  methods: {
    getData() {
      const self = this;

      getPlan(self.search).then(response => {
        self.render = response.data.data;

        setTimeout(() => {
          app.toast
            .create({
              text: "可左右滑动查看表格信息",
              position: "center",
              closeTimeout: 2000
            })
            .open();
        }, 300);
      });
    },
    initPicker() {
      const self = this;
      console.log(self.pickerData);

      self.pickerCustomToolbar = app.picker.create({
        inputEl: "#year-city-picker",
        rotateEffect: true,
        renderToolbar() {
          return (
            '<div class="toolbar">' +
            '<div class="toolbar-inner">' +
            '<div class="left">' +
            "</div>" +
            '<div class="right">' +
            '<a href="" class="link sheet-close popover-close">选择</a>' +
            "</div>" +
            "</div>" +
            "</div>"
          );
        },
        cols: [
          {
            values: self.pickerData.years
          },
          {
            values: self.pickerData.provinces
          }
        ],
        on: {
          closed(picker) {
            let s = picker.getValue();

            self.search = {
              year: s[0],
              city: s[1]
            };
          }
        }
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.title {
    font-size: 30px;
    color: #000;
    font-weight: 600;
    text-align: center;
  }
</style>
