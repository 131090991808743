<template>
  <f7-page name="webView">
    <f7-navbar no-shadow title="About" back-link="Back"></f7-navbar>
    <iframe v-if="url" :src="url" frameborder="0"></iframe>
  </f7-page>
</template>

<script>
import { Base64 } from "js-base64";

export default {
  data() {
    return { url: "" };
  },
  mounted() {
    this.params = this.$f7route.params;
    this.url = Base64.decode(this.params.url);
    console.log(this);
  }
};
</script>
