import HomePage from '@/pages/Home.vue';
import AcademyCatalogPage from '@/pages/AcademyCatalog.vue';

// Commons
import ContractPage from '@/pages/Commons/About.vue';
import WebViewPage from '@/pages/Commons/WebView.vue';
import ArticlePage from '@/pages/Commons/Article.vue';
import NotFoundPage from '@/pages/Commons/NotFound.vue';
import CampusMediumsVideoPage from '@/pages/Commons/MediumsVideo.vue';

// Admissions
import DynamicsPage from '@/pages/Admissions/Dynamics.vue';
import PolicyPage from '@/pages//Admissions/Policy.vue';
import PlanPage from '@/pages//Admissions/Plan.vue';
import MajorsPage from '@/pages//Admissions/Majors.vue';
import GuidancePage from '@/pages//Admissions/Guidance.vue';
import FractionalLinePage from '@/pages//Admissions/FractionalLine.vue';
import ArtRecruitQueryPage from '@/pages//Admissions/ArtRecruitQuery.vue';
import RecruitQueryPage from '@/pages//Admissions/RecruitQuery.vue';

// Campus
import CampusIntroductionPage from '@/pages/Campus/Introduction.vue';
import CampusMediumsPage from '@/pages/Campus/Mediums.vue';
import CampusAcademyPage from '@/pages/Campus/Academy.vue';

// Academy
import AcademyIntroductionPage from '@/pages/Academy/Introduction.vue';
import AcademyMajorsPage from '@/pages/Academy/Majors.vue';
import AcademySocialImpactPage from '@/pages/Academy/SocialImpact.vue';
import AcademyAlumniPage from '@/pages/Academy/Alumni.vue';
import AcademyMainJobDirectionPage from '@/pages/Academy/MainJobDirection.vue';
import AcademyGraduatesPage from '@/pages/Academy/Graduates.vue';
import AcademyScholarshipPage from '@/pages/Academy/Scholarship.vue';
import AcademyArticlePage from '@/pages/Academy/Article.vue';


// soft routes
var routes = [{
    path: '/',
    component: HomePage,
  },
  {
    path: '/AcademyCatalog',
    component: AcademyCatalogPage,
  },
  {
    path: '/About',
    component: ContractPage,
  },


  {
    path: '/Admissions/Dynamics',
    component: DynamicsPage,
  },
  {
    path: '/Admissions/Policy',
    component: PolicyPage,
  },
  {
    path: '/Admissions/Guidance',
    component: GuidancePage,
  },
  {
    path: '/Admissions/Plan',
    component: PlanPage,
  },
  {
    path: '/Admissions/Majors',
    component: MajorsPage,
  },
  {
    path: '/Admissions/FractionalLine',
    component: FractionalLinePage,
  },
  {
    path: '/Admissions/ArtRecruitQuery',
    component: ArtRecruitQueryPage,
  },
  {
    path: '/Admissions/RecruitQuery',
    component: RecruitQueryPage,
  },

  {
    path: '/Campus/Introduction',
    component: CampusIntroductionPage,
  },
  {
    path: '/Campus/Mediums',
    component: CampusMediumsPage,
  },

  {
    path: '/Campus/Academy/:academy',
    component: CampusAcademyPage,
  },
  {
    path: '/Academy/:academy/Introduction',
    component: AcademyIntroductionPage,
  },
  {
    path: '/Academy/:academy/Majors',
    component: AcademyMajorsPage,
  },
  {
    path: '/Academy/:academy/SocialImpact',
    component: AcademySocialImpactPage,
  },
  {
    path: '/Academy/:academy/Alumni',
    component: AcademyAlumniPage,
  },
  {
    path: '/Academy/:academy/MainJobDirection',
    component: AcademyMainJobDirectionPage,
  },
  {
    path: '/Academy/:academy/Graduates',
    component: AcademyGraduatesPage,
  },
  {
    path: '/Academy/:academy/Scholarship',
    component: AcademyScholarshipPage,
  },
  {
    path: '/AcademyArticle/:article_id',
    component: AcademyArticlePage,
  },


  {
    path: '/Web/:url',
    component: WebViewPage,
  },
  {
    path: '/Article/:article_id',
    component: ArticlePage,
  },
  {
    path: '/Mediums/Video/:article_id',
    component: CampusMediumsVideoPage,
  },

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;