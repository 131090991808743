import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=175d7175&scoped=true&"
import script from "./Article.vue?vue&type=script&lang=js&"
export * from "./Article.vue?vue&type=script&lang=js&"
import style0 from "./Article.vue?vue&type=style&index=0&id=175d7175&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175d7175",
  null
  
)

export default component.exports