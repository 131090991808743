import axios from "./request";
/**
 * 学院相关的api请求
 * 
 */

// 学院目录
export const getAcademyCatalog = () => {
    return axios.request({
        url: '/app/academy_catalogs',
        method: 'get'
    })
}
export const getAcademy = (params) => {
    return axios.request({
        url: '/app/academy_catalogs/' + params,
        method: 'get'
    })
}
// 学院简介
export const getIntroduction = (academy) => {
    return axios.request({
        url: `/app/academy/${academy}/introduction`,
        method: 'get'
    })
}
// 学院专业
export const getMajors = (academy, params) => {
    return axios.request({
        url: `/app/academy/${academy}/majors`,
        params: params,
        method: 'get'
    })
}
// 社会影响
export const getSocialImpact = (academy, params) => {
    return axios.request({
        url: `/app/academy/${academy}/effects`,
        params: params,
        method: 'get'
    })
}
// 知名校友
export const getAlumni = (academy, params) => {
    return axios.request({
        url: `/app/academy/${academy}/alumnis`,
        params: params,
        method: 'get'
    })
}
// 主要就业方向
export const getMainJobDirection = (academy, params) => {
    return axios.request({
        url: `/app/academy/${academy}/works`,
        params: params,
        method: 'get'
    })
}
// 历年毕业生去向
export const getGraduates = (academy, params) => {
    return axios.request({
        url: `/app/academy/${academy}/graduates`,
        params: params,
        method: 'get'
    })
}
// 专项奖学金
export const getScholarship = (academy, params) => {
    return axios.request({
        url: `/app/academy/${academy}/scholarship`,
        params: params,
        method: 'get'
    })
}


export const getArticle = (id) => {
    return axios.request({
        url: `/app/academy/posts/${id}`,
        method: 'get'
    })
}