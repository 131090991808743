<template>
  <f7-page
    no-toolbar
    :infinite="loadOptions.allowInfinite"
    :infinite-distance="50"
    :infinite-preloader="loadOptions.showPreloader"
    @infinite="loadMore"
    name="Academy-MainJobDirection"
  >
    <f7-navbar no-shadow title="就业主要方向及知名就业单位" back-link="Back"></f7-navbar>
    <f7-block v-if="!hasData" strong>这里没有找到相关数据</f7-block>
    <f7-list v-if="hasData">
      <f7-list-item
        v-for="(item, index) in render"
        :key="index"
        :title="item.title"
        :link="`/AcademyArticle/${item.post_id}`"
      ></f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { getMainJobDirection } from "@/api/academy";

export default {
  data() {
    return {
      render: [],
      loadOptions: {
        allowInfinite: true,
        showPreloader: true,
        page: 1
      },
      page: {
        meta: "",
        links: ""
      }
    };
  },
  mounted() {
    const self = this;
    this.params = this.$f7route.params;
    console.log(this.params);
    console.log(this);
    setTimeout(() => {
      this.fetchData(this.params.academy, 1);
    }, 500);
  },
  computed: {
    hasData() {
      return !!this.render.length;
    }
  },

  methods: {
    fetchData(academy, page) {
      const self = this;
      console.log(page);
      if (!academy || !page) {
        return;
      }

      getMainJobDirection(academy, {
        page
      }).then(response => {
        self.render = self.render.concat(response.data.data);
        self.page.meta = response.data.meta;
        self.page.links = response.data.links;

        if (!response.data.links.next) {
          self.loadOptions.showPreloader = false;
          self.loadOptions.allowInfinite = false;
        }
      });
    },

    loadMore() {
      fetchData(this.links.next);
    }
  }
};
</script>

<style scoped>
.segment p {
  text-indent: 2em;
}
</style>
