import axios from "./request";
/**
 * 首页招生相关请求接口
 * 
 */

//  招生动态
export const getDynamics = (params) => {
    return axios.request({
        url: 'admission/dynamics',
        params: params,
        method: 'get'
    })
}
// 历年分数线
export const getFractionalLine = (params) => {
    return axios.request({
        url: 'admission/fractional_line',
        params: params,
        method: 'get'
    })
}
// 招生指南
export const getGuidance = (params) => {
    return axios.request({
        url: 'admission/guidance',
        params: params,
        method: 'get'
    })
}
// 招生专业
export const getMajors = (params) => {
    return axios.request({
        url: 'admission/majors',
        params: params,
        method: 'get'
    })
}
// 招生计划
export const getPlan = (params) => {
    return axios.request({
        url: 'admission/plan',
        params: params,
        method: 'get'
    })
}
export const getPlanProvinces = (params) => {
    return axios.request({
        url: 'admission/plan/provinces',
        params: params,
        method: 'get'
    })
}
// 招生政策
export const getPolicy = (params) => {
    return axios.request({
        url: 'admission/policy',
        params: params,
        method: 'get'
    })
}
export const getMediums = (params) => {
    return axios.request({
        url: 'admission/mediums',
        params: params,
        method: 'get'
    })
}
// 普通高考录取
export const getRecruitQuery = (params) => {
    return axios.request({
        url: 'admission/recruit',
        params: params,
        method: 'post'
    })
}

// 普通高考录取
export const getEMSQuery = (params) => {
    return axios.request({
        url: 'admission/ems_express',
        params: params,
        method: 'post'
    })
}