import axios from "./request";
/**
 * 通用接口请求
 * 
 */

// 获取文章内容接口请求
export const getArticle = (params) => {
    return axios.request({
        url: 'admission/article',
        params: params,
        method: 'get'
    })
}