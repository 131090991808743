<template>
  <f7-page
    :infinite="loadOptions.allowInfinite"
    :infinite-distance="50"
    :infinite-preloader="loadOptions.showPreloader"
    @infinite="loadMore"
    name="Campus-Mediums"
  >
    <f7-navbar no-shadow title="影像交大" back-link="Back"></f7-navbar>

    <f7-list media-list v-if="hasData">
      <f7-list-item
        v-for="(item,index) in render"
        :key="`st2-${index}`"
        :title="item.article_name"
        :link="`/Mediums/Video/${item.article_id}`"
      >
        <img slot="media" :src="item.article_pic" width="60" height="40">
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { getMediums } from "@/api/admission";
import { ListPageDataDeal } from "@/libs/utils";

export default {
  data() {
    return {
      render: [],
      loadOptions: {
        allowInfinite: true,
        showPreloader: true,
        page: 1
      }
    };
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      getMediums({
        page: 1
      }).then(response => {
        ListPageDataDeal(self, response);
      });
    }, 500);
  },
  computed: {
    hasData() {
      return !!this.render.length;
    }
  },

  methods: {
    loadMore() {
      const self = this;

      if (!self.loadOptions.allowInfinite) {
        return;
      }

      getMediums({
        page: ++self.loadOptions.page
      }).then(response => {
        ListPageDataDeal(self, response);
      });
    }
  }
};
</script>
