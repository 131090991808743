<template>
  <f7-page no-toolbar name="Academy-Article">
    <f7-navbar no-shadow :title="render.title" back-link="Back">
      <f7-nav-right>
        <f7-link
          @click="showDetail"
          icon-ios="f7:info"
          icon-aurora="f7:info"
          icon-md="material:info"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block v-if="show" v-html="full_body">
      <p>content</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { getArticle } from "@/api/academy";

export default {
  data() {
    return {
      params: {},
      render: {},
      show: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.fetchData();
    }, 500);
  },
  computed: {
    full_body() {
      return this.render.body + this.attachments();
    }
  },
  methods: {
    fetchData() {
      const self = this;
      this.params = this.$f7route.params;

      getArticle(self.params.article_id).then(response => {
        console.log(response);
        self.render = response.data.data;
        self.show = true;
      });
    },
    showDetail() {
      let data = this.render;

      app.dialog
        .create({
          title: "更多信息",
          text: `<p style="text-align:left;">标题：${data.title}<br>发布人：${data.author}<br>时间：${data.created_at}</p>`,
          buttons: [
            {
              text: "关闭"
            }
          ]
        })
        .open();
    },
    attachments() {
      console.log(this.render);
      if (this.render.docs.length == 0) {
        return "";
      }

      let docs = this.render.docs;
      let string = "<div class='post-attachments'><p>本文附件</p>";

      docs.forEach((element, index) => {
        string += `<a class="external" target="_blank" href="${element}">附件${index +
          1}</a><br>`;
      });
      string += "</div>";

      return string;
    }
  }
};
</script>

<style lang="sass" scoped>
.title {
    font-size: 30px;
    color: #000;
    font-weight: 600;
    text-align: center;
  }
</style>
