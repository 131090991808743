import axios from "./request";
/**
 * 首页请求接口
 * 
 */

// 首页数据
export const getHomeData = () => {
    return axios.request({
        url: '/app/home',
        method: 'get'
    })
}