<template>
  <f7-page no-toolbar name="Academy-Introduction">
    <f7-navbar no-shadow title="学院介绍" back-link="Back"></f7-navbar>
    <f7-block strong v-if="!show">没有找到相应内容</f7-block>
    <f7-block v-if="show" v-html="render.body">
      <p>content</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { getIntroduction } from "@/api/academy";

export default {
  data() {
    return {
      params: {},
      render: {},
      show: false
    };
  },
  mounted() {
    const self = this;
    this.params = this.$f7route.params;

    setTimeout(() => {
      getIntroduction(self.params.academy).then(response => {
        console.log(response);
        self.render = response.data.data;
        self.show = true;
      });
    }, 500);
  }
};
</script>


<style scoped>
.segment p {
  text-indent: 2em;
}
</style>
