<template>
  <f7-page name="Admission-ArtRecruitQuery">
    <f7-navbar no-shadow title="艺术类校考成绩查询" back-link="Back"></f7-navbar>
    <f7-list v-if="c_flag.query_show" form no-hairlines-md>
      <f7-list-input
        label="身份证号码"
        type="text"
        placeholder="请输入身份证号码"
        :value="identical_number"
        @input="identical_number = $event.target.value"
        clear-button
        required
        validate
      ></f7-list-input>

      <f7-list-input
        label="考试类别"
        type="select"
        :value="classification"
        @input="classification = $event.target.value"
        info
        required
      >
        <option value="2">艺术类（美术）</option>
        <option value="3">艺术类（音乐、舞蹈）</option>
      </f7-list-input>
    </f7-list>
    <f7-list v-if="c_flag.query_show">
      <f7-button round raised fill @click="submit()" text="查询" class="submit-btn"></f7-button>
    </f7-list>

    <!-- 信息展示区域 -->
    <div v-if="c_flag.result_show" class="data-table card">
      <div class="card-header">
        <div v-if="info.status" class="data-table-title">恭喜您，成绩合格</div>
        <div v-else class="data-table-title">很遗憾，成绩不合格</div>
        <!-- <div class="data-table-actions">
          <a class="link icon-only" @click="ems()">
            <i class="icon f7-icons if-not-md">more_vertical_round</i>
            <i class="icon material-icons md-only">more_vert</i>
          </a>
        </div>-->
      </div>
      <table>
        <tbody>
          <tr>
            <td class="label-cell">考生姓名</td>
            <td class="numeric-cell">{{ info.name }}</td>
          </tr>
          <tr>
            <td class="label-cell">考生号</td>
            <td class="numeric-cell">{{ info.test_no }}</td>
          </tr>
          <tr>
            <td class="label-cell">考生省份</td>
            <td class="numeric-cell">{{ info.province }}</td>
          </tr>
          <tr>
            <td class="label-cell">身份证号</td>
            <td class="numeric-cell">{{ info.id_no }}</td>
          </tr>
          <tr>
            <td class="label-cell">排 名</td>
            <td class="numeric-cell">{{ info.grid }}</td>
          </tr>
          <tr v-for="(item, index) in info.score_list" :key="index">
            <td class="label-cell" v-if="item.score">{{ item.item }}</td>
            <td class="numeric-cell" v-if="item.score">{{ item.score }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </f7-page>
</template>

<script>
import { getRecruitQuery, getEMSQuery } from "@/api/admission";

export default {
  data() {
    return {
      c_flag: {
        query_show: true,
        result_show: false,
        ems_show: false
      },
      identical_number: "",
      classification: 2
    };
  },

  mounted() {},

  methods: {
    submit() {
      let i = this.identical_number;
      let c = this.classification;

      if (!i || i.length != 18) {
        return app.notification
          .create({
            subtitle: "请输入正确的身份证号码"
          })
          .open();
      }

      if (!c) {
        return app.notification
          .create({
            subtitle: "请选择考试类别"
          })
          .open();
      }

      getRecruitQuery({
        type: c,
        id_no: i
      })
        .then(response => {
          console.log(response);
          this.info = response.data.data;
          this.c_flag.query_show = false;
          this.c_flag.result_show = true;
          console.log(this);

          // var toastIcon = app.toast
          //   .create({
          //     icon:
          //       app.theme === "ios"
          //         ? '<i class="f7-icons">bell</i>'
          //         : '<i class="material-icons">bell</i>',
          //     text: "点击卡片右上角的图标可以查询快递信息",
          //     position: "center",
          //     closeTimeout: 3000
          //   })
          //   .open();
        })
        .catch(error => {
          if (error.response.status == "404") {
            return app.notification
              .create({
                subtitle:
                  "没有找到相应录取信息，请检查考试类别有没有选错或者身份信息是否正确。"
              })
              .open();
          } else {
            return app.notification
              .create({
                subtitle: error.response.data.message
                  ? error.response.data.message
                  : "服务器故障。"
              })
              .open();
          }
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.submit-btn {
    width: 60%;
    margin: 0 auto;
}
</style>
