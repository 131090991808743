export const ListPageDataDeal = (context, response) => {
    console.log(context, response)
    let meta = response.data.meta;
    let data = response.data.data;

    if (!meta.next) {
        console.log(meta);
        context.loadOptions.showPreloader = false;
        context.loadOptions.allowInfinite = false;

        console.log(context.loadOptions)
    }

    context.render = context.render.concat(data);
}