<template>
  <f7-page name="not-found">
    <f7-navbar no-shadow :title="title" back-link="Back"></f7-navbar>
    <f7-block strong v-html="pageTip" class="display">
      <p>Sorry</p>
      <p>Page not found.</p>
    </f7-block>
    <f7-list inset v-if="button.show">
      <f7-list-button @click="button1" :title="button.title" :color="button.color"></f7-list-button>
    </f7-list>
  </f7-page>
</template>
<script>
export default {
  data() {
    return {
      title: "Not found",
      pageTip: "<p>Sorry</p><p>Page not found.</p>",
      button: {
        show: false,
        title: "Button",
        color: "blue",
        location: ""
      }
    };
  },
  mounted() {
    const self = this;
    const app = this.$f7;
    const route = this.$f7route;
    const router = this.$f7.views.main.router;

    console.log(route);
    let url = route.url;
    url = url.match(/http:\/.*|https:\/.*/i);
    if (url) {
      url = url[0];
      url = url
        .replace(/^http:\//i, "http://")
        .replace(/^https:\/$/i, "https://");
      this.title = "外部链接访问确认";
      this.pageTip =
        "<p>您正在访问外部链接或下载外部文件，链接为：</p><p style='text-indent:2em; font-size:1.5em;'>" +
        url +
        "</p>" +
        "<p>我们不能确保外部链接的安全性，请确认后再访问。</p>";
      this.button.show = true;
      this.button.title = "已确认，继续访问";
      this.button.color = "orange";
      this.button.location = url;
    }
  },
  methods: {
    button1() {
      window.open(this.button.location);
    }
  }
};
</script>

<style scoped>
.display {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
