import Axios from 'axios';
/**
 * 初始化axios
 * 返回一个自定义后的axios实例
 * 
 */

// let baseUrl = 'http://test.api.megoc.mine1083.cn/api/';
let baseUrl = process.env.NODE_ENV === 'development' ? 'http://test.api.megoc.mine1083.cn/api/' : 'http://zjc.ecjtu.edu.cn/nano_admin/api/';

const axios = Axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
        'X-APP': 'PWA'
    }
});

// 添加请求拦截器
axios.interceptors.request.use(
    function (config) {
        app.dialog.preloader("数据加载中");
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        app.dialog.close();
        return response;
    },
    function (error) {
        console.log(error)
        console.log(app)
        app.dialog.close();

        try {
            let status_code = error.response.status;
            let response = error.response.data;

            switch (status_code) {
                default:
                    app.notification
                        .create({
                            subtitle: response.message
                        })
                        .open();
                    break;
            }
        } catch (e) {
            app.notification
                .create({
                    subtitle: response.message
                })
                .open();
        }

        return Promise.reject(error);
    }
);

export default axios;