<template>
  <f7-page name="article" hide-toolbar-on-scroll>
    <f7-navbar no-shadow :title="render.article_name" back-link="Back">
      <f7-nav-right>
        <f7-link
          @click="showDetail"
          icon-ios="f7:info"
          icon-aurora="f7:info"
          icon-md="material:info"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block v-if="show" v-html="full_content">
      <p>content</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { getArticle } from "@/api/common";
export default {
  data() {
    return {
      params: {},
      render: {},
      show: false
    };
  },
  computed: {
    full_content() {
      return this.render.content + this.attachments();
    }
  },
  mounted() {
    const self = this;
    this.params = this.$f7route.params;

    setTimeout(() => {
      getArticle({
        article_id: self.params.article_id
      }).then(response => {
        console.log(response);
        self.render = response.data.data;
        self.show = true;
      });
    }, 500);
  },
  methods: {
    showDetail() {
      let data = this.render;

      app.dialog
        .create({
          title: "更多信息",
          text: `<p style="text-align:left;">标题：${
            data.article_name
          }<br>作者：${data.author}<br>时间：${data.create_time}</p>`,
          buttons: [
            {
              text: "关闭"
            }
          ]
        })
        .open();
    },
    attachments() {
      if (this.render.docs.length == 0) {
        return "";
      }

      let docs = this.render.docs;
      let string = "<div class='post-attachments'><p>本文附件</p>";

      docs.forEach(element => {
        string += `<a class="external" target="_blank" href="${
          element.doc_url
        }">${element.doc_name}</a><br>`;
      });
      string += "</div>";

      return string;
    }
  }
};
</script>

<style lang="sass" scoped>
.title {
    font-size: 30px;
    color: #000;
    font-weight: 600;
    text-align: center;
  }
</style>
