<template>
  <f7-page name="home">
    <f7-navbar no-shadow>
      <f7-nav-title>华东交通大学招生网</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-ios="f7:menu"
          icon-aurora="f7:menu"
          icon-md="material:menu"
          panel-open="right"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-swiper pagination :params="slidersOptions" class="sliders home-sliders">
      <f7-swiper-slide v-for="(img, index) in sliders" :key="'slider-'+index">
        <a :href="img.jump_url" class="external" target="_blank">
          <img :alt="img.title" :src="img.picture_url">
        </a>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-block class="grid-apps">
      <f7-row no-gap>
        <f7-col>
          <a href="/Admissions/RecruitQuery">
            <f7-col class="col-lnfs col-bg">高考录取查询</f7-col>
          </a>
        </f7-col>
        <f7-col>
          <a href="/Admissions/ArtRecruitQuery">
            <f7-col class="col-zszy col-bg">艺术类专业成绩查询</f7-col>
          </a>
        </f7-col>
      </f7-row>
      <f7-row no-gap style="margin-top: 8px;">
        <f7-col width="25">
          <a href="/Admissions/Dynamics">
            <f7-col class="col-zsdt col-bg">招生动态</f7-col>
          </a>

          <a href="/Admissions/Policy">
            <f7-col class="col-zszc col-bg">招生政策</f7-col>
          </a>
        </f7-col>
        <f7-col width="50" class="col-zszn col-bg">
          <a href="/Admissions/Guidance">
            <f7-col style="width:100%; height:100%">
              <div class="bkzn-title">
                <div>招生指南</div>
              </div>
            </f7-col>
          </a>
        </f7-col>
        <f7-col width="25">
          <a href="/Admissions/Plan">
            <f7-col class="col-zsjh col-bg">招生计划</f7-col>
          </a>
          <a href="/Admissions/Majors">
            <f7-col class="col-zszy col-bg">招生专业</f7-col>
          </a>
        </f7-col>
      </f7-row>

      <f7-row style="margin-top: 8px;" no-gap>
        <f7-col width="75">
          <a href="/Campus/Mediums">
            <f7-col class="col-jdyx col-bg">影像交大</f7-col>
          </a>
          <f7-row>
            <f7-col width="40" class="col-lnfs col-bg">
              <a href="/Admissions/FractionalLine">
                <f7-col>历年分数</f7-col>
              </a>
            </f7-col>
            <f7-col width="60" class="col-xxgk col-bg">
              <a href="/Campus/Introduction">
                <f7-col>学校概况</f7-col>
              </a>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col width="25" class="col-lxwm col-bg">
          <a href="/About">
            <f7-col>
              <div class="lxwm-title">联系我们</div>
            </f7-col>
          </a>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { getHomeData } from "@/api/index";

export default {
  data() {
    return {
      sliders: [],
      data: {},
      slidersOptions: {
        speed: 500,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
          delay: 3500
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets"
        }
      }
    };
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      getHomeData().then(response => {
        let data = response.data.data;

        console.log(data);
        self.data = data;
        self.sliders = data.slides;

        console.log(self);

        // reload swiper
        self.$f7.swiper.destroy(".home-sliders");
        setTimeout(() => {
          self.$f7.swiper.create(".home-sliders", self.slidersOptions);
        }, 50);
      });
    }, 500);
  }
};
</script>

<style lang="sass" scoped>
.sliders {
  height: 250px;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
